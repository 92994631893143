//*----------  COMPONENTS  ----------*/
import DashBoard              from '@/components/DashBoard/DashBoard.vue'
import DataGrid               from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
import FormReport             from '@/components/Forms/FormReport/FormReport.vue'
import InitFormAdministrador  from './componentes/InitForm/FilterAdmin.vue'
import InitFormResponsable    from './componentes/InitForm/FilterResponsalbe.vue'
import InitFormUsuario        from './componentes/InitForm/FilterUsuario.vue'
import service                from '@/services/soporte'
import getFile                from '../../../../services/getFile'
    // ? VUEX
import TableTicket            from './View/TabTicket/TabTicket.vue'
import CreateT                from './View/Create/CreateTicket.vue'
import AssignT                from './View/Assign/ViewAssign.vue'
import AttendT                from './View/Attend/ViewAttend.vue'
import CierreT                from './View/Cierre/CierreTicket.vue'
import PauseT                 from './View/Pause/ViewPause.vue'
import FinalizeT              from './View/Finalize/ViewFinalize.vue'
import PanelT                 from './View/Panel/CreatePanel.vue'
import authStore              from '@/store/modules/authStore'
import FilterFrame            from '@/components/FilterFrame/FilterFrame.vue'
import { 
    mapActions,
    mapGetters,
    mapState
}                     from 'vuex';

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Ticket",
    components : {
        DashBoard,
        InitFormAdministrador, // Administrador
        InitFormResponsable, // Responsable
        InitFormUsuario, // Usuario
        DataGrid,
        FormReport,
        CreateT,
        AssignT,
        AttendT,
        CierreT,
        PauseT,
        FinalizeT,
        PanelT,
        TableTicket,
        FilterFrame
    },
    props : {
        dataConcepto: Object
    },
    data : () => ({
        isNew           : true,
        isNewPanel      : true,
        tipoUsuario     : null, // 1:Admin, 2:Resp, 3:User
        dataGridRef     : null,
        dialog          : false,
        dialogAssign    : false,
        dialogAttend    : false,
        dialogCierre    : false,
        dialogPause     : false,
        dialogFinalize  : false,
        dialogPanel     : false,
        isEditAssign    : false,
        isEditAttend    : false,
        isEditPause     : false,
        user            : false,
        imgSrc          : getFile.url(1, 100, '228.png'),
        mainTab         : null,
        oldObject       : {},
        oldObjectP      : {},
        oldObjectCierre : {},
        dataTicket      : {},
        containerFluid : true
    }),
    computed : {
        ...mapGetters("Asignacion", ["getDataAsignacion"]),
        ...mapState("Ticket", ["dataRender", "dataSelected"]),
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions("Asignacion",  ["setDataAsignacion"] ),
        ...mapActions("Ticket",  ["setDataRender","setDataSelected","setDataSource"] ),
        loadEvent( {idRlCA, idBtn} ) {
            switch( idBtn ) {
                case "btnNuevo": 
                    this.isNew  = true;
                    this.setDialog({
                        key    : "dlgCreateT",
                        status : true
                    });
                    break;
                case "btnPanel":
                    this.isNewPanel = true;
                    this.setDialog({
                        key    : "dlgPanelGral",
                        status : true
                    });
                    break;
                break;
            }
        },
        eventActionTable({ event, dataAction }) {
            this.$emit("eventActionTable", { event, dataAction })
            switch( dataAction.accion ) {
                case "Edicion":
                    if ( event.row.data.id_baseCtEstatus != 1 ) {
                        this.$Msg.error(`El ticket debe estar documentado para poder editarlo`)
                        return false;
                    }
                    this.isNew  = false;
                    this.oldObject = {
                        id_soporteTrTicket      : event.row.data.id,
                        folio                   : event.row.data.folio,
                        titulo                  : event.row.data.titulo,
                        id_baseCtEstatus        : event.row.data.id_baseCtEstatus,
                        id_soporteCtEvento      : event.row.data.id_soporteCtEvento,
                        evento                  : event.row.data.evento,
                        id_soporteCtServicio    : event.row.data.id_soporteCtServicio,
                        id_adminCtUser          : event.row.data.id_adminCtUser,
                        id_adminCtSucursal      : event.row.data.id_adminCtSucursal,
                        descripcion             : event.row.data.descripcion,
                        files                   : event.row.data.files,
                        updated_at              : event.row.data.fechaU,
                        id_soporteTrTicketBase  : event.row.data.id_soporteTrTicketBase,
                        activo                  : 1
                    };
                    this.setDialog({
                        key    : "dlgCreateT",
                        status : true
                    });
                    break;
                case "Asignar":
                    this.asignar( event )
                    break;
                case "Atender":
                    this.atender( event );
                    break;
                case "Cerrar":
                    if ( event.row.data.id_baseCtEstatus != 4 ) {
                        this.$Msg.error(`El ticket debe estar finalizado para poder cerrarlo`)
                        return false;
                    }
                    this.oldObjectCierre = {
                        id_soporteTrTicket      : event.row.data.id,
                        folio                   : event.row.data.folio,
                        titulo                  : event.row.data.titulo,
                        updated_at              : event.row.data.fechaU,
                    };
                    this.dialogCierre  = true;
                    this.setDialog({
                        key    : "dialogCierre",
                        status : true
                    });
                    break;
                case "Eliminar":
                    this.$Msg.confirma(`¿Desea eliminar el ticket "${event.row.data.folio}"?`).then( value => {
                        if( value.value )
                           this.eliminar( event );
                    })
                    break;
                case "Cancelar":
                    let banEst = true;
                    if( event.row.data.id_baseCtEstatus != 1 ){
                        banEst = false;
                        this.$Msg.error(`No se puede cancelar un ticket con estatus diferente a documentado`)
                    }
                    if( banEst ){
                        let obj = {
                            title       : 'Observación',
                            placeHolder : 'Observaciones',
                            btnConfirm  : 'Aceptar'
                        }
                        let msgCancel = `¿Desea cancelar el ticket "${event.row.data.folio}"?`;
                        this.$Msg.textarea( msgCancel, obj ).then( value => {
                            if( value.value )
                                this.cancelar( event, value.value );
                        })
                    }
                    break;
                case "Pausar":
                    this.pausar( event );
                    break;
                case "Finalizar":
                    this.finalizar( event );
                    break;
                case "EditGral":
                    this.editGral( event );
                    break;
                default:
                    break;
            }
        },
        closeDialog( value ) {
            this.dialog = value;
        },
        closeDialogAssign( value ) {
            this.dialogAssign = false;
        },
        closeDialogAttend( value ) {
            this.dialogAttend = false;
        },
        closeDialogCierre( value ) {
            this.dialogCierre = false;
        },
        closeDialogPause( value ) {
            this.dialogPause = false;
        },
        closeDialogFinalize( value ) {
            this.dialogFinalize = false;
        },
        closeDialogPanel( value ) {
            this.dialogPanel = value;
        },
        edit( data ){
            this.clear();

        },
        clear () {

        },
        asignar( data ){
            let dataRow = data.row.data;
            let banEst  = true;

            if( !( dataRow.id_baseCtEstatus == 1 || dataRow.id_baseCtEstatus == 2 ) ){
                banEst = false;
                this.$Msg.error(`No se puede asignar el ticket si no esta como documentado o asignado`)
            }

            if( dataRow.id_soporteCtServicio == null )
                this.$Msg.error(`No se puede asignar el ticket si aún no se ha seleccionado el tipo de servicio`)

            if( dataRow.id_adminCtSucursal == null )
                this.$Msg.error(`No se puede asignar el ticket si aún no se ha seleccionado una sucursal`)

            if( dataRow.id_adminCtUser == null )
                this.$Msg.error(`No se puede asignar el ticket si aún no se ha seleccionado el usuario que solicita`)
            
            if( dataRow.id_soporteCtEvento == null )
                this.$Msg.error(`No se puede asignar el ticket si aún no se ha seleccionado el tipo de evento`)

            if( dataRow.id_soporteCtServicio != null && dataRow.id_soporteCtEvento != null && dataRow.id_adminCtSucursal != null && dataRow.id_adminCtUser != null && banEst ){ 
                
                this.dataTicket = {
                    id_soporteTrTicket  : dataRow.id,
                    estatus             : dataRow.estatus,
                    id_soporteCtServicio: dataRow.id_soporteCtServicio,
                    updated_at          : dataRow.fechaU
                }

                this.isEditAssign = dataRow.id_baseCtEstatus == 2;
                this.setDialog({
                    key    : "dlgAssign",
                    status : true
                });
            }
        },
        atender( data ){
            let dataRow = data.row.data;
            let banEst  = true;
            
            if( dataRow.id_baseCtEstatus != 2 ){
                banEst = false;
                this.$Msg.error(`No se puede atender el ticket por que no ha sido asignado`)
            }

            if( banEst ){
                this.dataTicket = {
                    id_soporteTrTicket  : dataRow.id,
                    estatus             : dataRow.estatus,
                    id_soporteCtServicio: dataRow.id_soporteCtServicio,
                    updated_at          : dataRow.fechaU
                }

                this.isEditAttend = false; // FIXME: ¿Habrá edición?
                this.setDialog({
                    key    : "dlgAttend",
                    status : true
                });
            }
        },
        eliminar( data ) {
            let dataRow = data.row.data;
            if( dataRow.id_baseCtEstatus != 1 ){
                this.$Msg.error(`No se puede eliminar el ticket en estatus documentado`)
                return;
            }
            let objTicket = {
                id_soporteTrTicket : dataRow.id,
                activo             : -1,
                updated_at         : dataRow.fechaU
            };
            let arrInfo  = [];
            arrInfo.push(this.$JsTable.getFormat("soporteTrTicket",  "Update", [ objTicket ],  ["id_soporteTrTicket"]));
            service.SopU_eliminarTicket( arrInfo ).then( result => {
                if( this.$Msg.fn( {data : result, message : "Ticket eliminado correctamente" } )) {
                    this.readTableTicket();
                }
            })

        },
        cancelar( data, obs ) {
            let arrInfo       = [];
            let dataRow       = data.row.data;
            let observacion   = obs;
            if( dataRow.id_baseCtEstatus != 1 ){
                this.$Msg.error(`No se puede cancelar el ticket en estatus diferente a documentado`)
                return;
            }
            let objTicket = {
                id_soporteTrTicket : dataRow.id,
                id_baseCtEstatus   : 6,
                updated_at         : dataRow.fechaU
            };

            this.$fn.setIdDocMod( 1 ); // Soporte
            
            arrInfo.push( this.$JsTable.getFormat( "soporteTrTicket", "Update", [ objTicket ], [ "id_soporteTrTicket" ] ) );
            arrInfo.push( this.$JsTable.getFormat( "baseDtFlujoEstatus", "Insert", [ this.$fn.getTableDtFlujo( dataRow.id, 6 ) ], [ "id_baseDtFlujoEstatus" ] ) );
            arrInfo.push( this.$JsTable.getFormat( "baseDsFlujoEstatus", "Insert", [ this.$fn.getTableDsFlujo( observacion ) ], [ "id_baseDsFlujoEstatus" ] ) );

            service.SopU_cancelarTicket( arrInfo ).then( result => {
                if( this.$Msg.fn( { data : result, message : "Ticket cancelado correctamente" } ) ) {
                    this.readTableTicket();
                }
            })

        },
        pausar( data ){
            let dataRow = data.row.data;
            let banEst  = true;
            
            if( dataRow.id_baseCtEstatus != 3 && dataRow.id_baseCtEstatus != 7 ){
                banEst = false;
                this.$Msg.error(`No se puede pausar un ticket con estatus diferente a atendido o pausado`)
            }

            if( banEst ){
                this.dataTicket = {
                    id_soporteTrTicket  : dataRow.id,
                    id_baseCtEstatus    : dataRow.id_baseCtEstatus,
                    updated_at          : dataRow.fechaU
                }
                this.isEditPause = false; // FIXME: ¿Habrá edición?
                // this.dialogPause  = true;
                this.setDialog({
                    key    : "dlgPause",
                    status : true
                });
            }
        },
        finalizar( data ){
            let dataRow = data.row.data;
            let banEst  = true;
            
            if( dataRow.id_baseCtEstatus != 3 ){
                banEst = false;
                this.$Msg.error(`No se puede pausar un ticket con estatus diferente a atendido`)
            }

            if( banEst ){
                this.dataTicket = {
                    id_soporteTrTicket  : dataRow.id,
                    estatus             : dataRow.estatus,
                    updated_at          : dataRow.fechaU
                }
                this.dialogFinalize  = true;
                this.setDialog({
                    key    : "dialogFinalize",
                    status : true
                });
            }
        },
        editGral( data ){
            let dataRow = data.row.data;
            this.oldObjectP = {
                id_soporteTrTicket      : dataRow.id,
                folio                   : dataRow.folio,
                titulo                  : dataRow.titulo,
                id_baseCtEstatus        : dataRow.id_baseCtEstatus,
                id_soporteCtEvento      : dataRow.id_soporteCtEvento,
                evento                  : dataRow.evento,
                id_soporteCtServicio    : dataRow.id_soporteCtServicio,
                id_adminCtUser          : dataRow.id_adminCtUser,
                id_adminCtSucursal      : dataRow.id_adminCtSucursal,
                descripcion             : dataRow.descripcion,
                files                   : dataRow.files,
                updated_at              : dataRow.fechaU,
                id_soporteTrTicketBase  : dataRow.id_soporteTrTicketBase,
                activo                  : 1
            };
            this.isNewPanel  = false;
            this.setDialog({
                key    : "dlgPanelGral",
                status : true
            });
        },
        readTableTicket() {
            service.SopR_dataSourceTickets().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ))
            })
        },
        setDataGridRef( dataGrid ) {
            this.dataGridRef = dataGrid
        },
        applyFilter( arrFilter ) {
            arrFilter.length > 0 ? this.dataGridRef.filter(arrFilter) : this.dataGridRef.clearFilter();
        }
    },
    created() {
        service.SopR_initFormTicket(  ).then( result => {
            let arrUser         = [];
            let arrSucursal     = [];
            let arrServicio     = [];
            let arrEvento       = [];
            let arrEstatus      = [];
            let arrResponsable  = [];
            let arrAuxiliar     = [];
            let arrEquipoAux    = [];
            let user            = this.$JsTable.toObj( result.data.usuarios );
            let sucursal        = this.$JsTable.toObj( result.data.sucursales );
            let servicio        = this.$JsTable.toObj( result.data.servicios );
            let evento          = this.$JsTable.toObj( result.data.eventos );
            let estatus         = this.$JsTable.toObj( result.data.estatus );
            let responsable     = this.$JsTable.toObj( result.data.responsable );
            let auxiliar        = this.$JsTable.toObj( result.data.auxiliar );
            let equipoAux       = this.$JsTable.toObj( result.data.equipoAux );

            user.forEach(element=>{
                arrUser.push({
                    value: element.id,
                    text : element.name
                })
            })

            sucursal.forEach(element=>{
                arrSucursal.push({
                    value: element.id,
                    text : element.name,
                    idUser : element.idUser
                })
            })

            servicio.forEach(element=>{
                arrServicio.push({
                    value: element.id,
                    text : element.servicio
                })
            })
            evento.forEach(element=>{
                arrEvento.push({
                    id: element.id,
                    name : element.evento,
                    description : element.descripcion
                })
            })
            estatus.forEach(element=>{
                arrEstatus.push({
                    value: element.id,
                    text : element.estatus
                })
            })
            responsable.forEach(element=>{
                arrResponsable.push({
                    value: element.id,
                    text : element.name
                })
            })
            auxiliar.forEach(element=>{
                arrAuxiliar.push({
                    value: element.id,
                    text : element.name
                })
            })
            equipoAux.forEach(element=>{
                arrEquipoAux.push({
                    value: element.id,
                    text : element.name
                })
            })

            this.setDataRender( { key: "usuario",       data: arrUser } );
            this.setDataRender( { key: "sucursal",      data: arrSucursal } );
            this.setDataRender( { key: "servicio",      data: arrServicio } );
            this.setDataRender( { key: "evento",        data: arrEvento } );
            this.setDataRender( { key: "estatus",       data: arrEstatus } );
            this.setDataRender( { key: "responsable",   data: arrResponsable } );
            this.setDataRender( { key: "auxiliar",      data: arrAuxiliar } );
            this.setDataRender( { key: "equipoAux",     data: arrEquipoAux } );

        } );
    },
    mounted() {
        this.tipoUsuario = authStore.getters.getUser().idPerfil;
    },
}
