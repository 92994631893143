/**
 * Valores por defecto para configuración del componente
 */
const defaultValues = {    
    defaultValue : 1,
    cols       : {
        xs: 12,
        sm: 12,
        md: 12,
    }
}

import {
    mapState,
    mapActions
} from 'vuex';

export default {
    name  : "RadioGroup",
    props : {
        /**
         * @property {Object}   config              - Configuración para el componente
         * @property {string}   config.component    - Nombre del componente a renderizar 'RadioGroup'
         * @property {string}   config.label        - Etiqueta a mostrar en v-radio-group
         * @property {string}   config.name         - Nombre para propiedad v-model y obtención en el store
         * @property {string?}  config.defaultValue - Valor por defecto del componente, sera respetado al limpiar el formulario
         * @property {string[]} config.elements     - Arreglo de objetos formados por las propiedades value y label
         * @property {Object?}  config.col          - Definición de columnas para etiqueta v-col   
         */
        config : {
            type     : Object,
            required : true
        }
    },  
    computed:{
        configDefault(){
            return {
                ...defaultValues,
                ...this.config
            }
        },
        ...mapState("FormStore", ["forms"]),
        setData : {            
            get(){
                return this.forms[this.config.frm][this.config.name]
            },
            set(val){
                this.setValueFrm({
                    frm  : this.config.frm,
                    key  : this.config.name,
                    data : val
                })   
            }
        }
    },
    created(){
        this.setInitValueFrm({
            frm          : this.config.frm,
            key          : this.configDefault.name,
            defaultValue : this.configDefault.defaultValue
        })
    },
    methods:{
        ...mapActions("FormStore", ["setInitValueFrm", "setValueFrm"])
    }
}