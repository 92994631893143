const defaultValues = {
    width       : "290px",
    defaultValue: ['', ''],    
    cols        : {
        xs: 12,
        sm: 12,
        md: 12,
    },
}

import {
    mapState,
    mapActions
} from 'vuex';

export default {
    name    : "DateTimeRange",
    props   : {
        config  : {
            type        : Object,
            required    : true
        }
    },
    data : () => ({
        menu: false,
    }),
    computed    : {
        configDefault(){
            return {
                ...defaultValues,
                ...this.config
            }
        },
        prependIcon(){
            return this.configDefault.hasOwnProperty("icon") ? { "prepend-icon" : 'mdi-' + this.configDefault.icon } : ""
        },
        ...mapState( "FormStore", [ "forms" ] ),
        setData : {
            get(){
                return this.forms[this.config.frm][this.config.name]
            },
            set( val ){
                this.setValueFrm({
                    frm     : this.config.frm,
                    key     : this.config.name,
                    data    : val
                })
            }
        },
    },
    created(){
        this.setInitValueFrm({
            frm             : this.config.frm,
            key             : this.configDefault.name,
            defaultValue    : this.configDefault.defaultValue
        })
    },
    methods : {
        ...mapActions( "FormStore", [ "setInitValueFrm", "setValueFrm" ] )
    }
}