/**
 * Valores por defecto para configuración del componente
 */
const defaultValues = {    
    defaultValue: '',
    cols        : {
        xs: 12,
        sm: 12,
        md: 12,
    },
    properties :{
        clearable: true,
        required : true
    }
}

import {
    mapState,
    mapActions
} from 'vuex';

export default{
    name  : "TextField",
    props : {
        config :{
            type     : Object,
            required : true
        }
    },    
    computed:{
        configDefault(){
            return {
                ...defaultValues,
                ...this.config
            }
        },
        ...mapState("FormStore", ["forms"]),
        setData : {            
            get(){
                return this.forms[this.config.frm][this.config.name]
            },
            set(val){
                this.setValueFrm({
                    frm  : this.config.frm,
                    key  : this.config.name,
                    data : val
                })   
            }
        }
    },
    created(){
        this.setInitValueFrm({
            frm          : this.config.frm,
            key          : this.configDefault.name,
            defaultValue : this.configDefault.defaultValue
        })
    },
    methods:{
        ...mapActions("FormStore", ["setInitValueFrm", "setValueFrm"])
    },
}