/**
 * Valores por defecto para configuración del componente
 */
const defaultValues = {    
    properties   : {
        required     : false,
        autocomplete : "off"
    },    
    defaultValue : "",
    tooltip      : "Refrescar datos",
    outerIconDef : "mdi-refresh",
    cols       : {
        xs: 12,
        sm: 12,
        md: 12,
    }
}

import {
    mapState,
    mapActions
} from 'vuex';

export default {
    name : "AutoComplete",    
    props :{
        /**
         * @property {Object}        config              - Configuración para el componente
         * @property {string}        config.component    - Nombre del componente a renderizar 'AutoComplete'
         * @property {string}        config.label        - Etiqueta a mostrar en v-radio-group
         * @property {string}        config.name         - Nombre para propiedad v-model y obtención en el store
         * @property {string?}       config.defaultValue - Valor por defecto del componente, sera respetado al limpiar el formulario
         * @property {string[]}      config.items        - Arreglo de objetos formados por las propiedades id y name
         * @property {string?}       config.icon         - Nombre del icono a mostrar nombrado sin el prefijo mdi-
         * @property {boolean?}      config.outerIcon    - Especificación para mostrar icono a la derecha
         * @property {string?}       config.tooltip      - Mensaje de tooltip para icono a la derecha
         * @property {outerIconDef?} config.outerIconDef - Nombre del icono a mostrar a la derecha nombrado sin el prefijo mdi-
         * @property {eventIcon?}    config.eventIcon    - Evento para el icono a la derecha
         * @property {Object?}       config.col          - Definición de columnas para etiqueta v-col   
         */
        config : {
            type     : Object,
            required : true
        }
    },    
    computed:{
        configDefault(){
            return {
                ...defaultValues,
                ...this.config
            }
        },
        prependIcon(){
            return this.configDefault.hasOwnProperty("icon") ? { "prepend-icon" : 'mdi-' + this.configDefault.icon } : ""
        },
        ...mapState("FormStore", ["forms"]),
        setData: {
            get(){
                return this.forms[this.config.frm][this.config.name]
            },
            set(val){
                this.setValueFrm({
                    frm  : this.config.frm,
                    key  : this.config.name,
                    data : val
                })   
            }
        }
    },
    created(){
        this.setInitValueFrm({
            frm          : this.config.frm,
            key          : this.configDefault.name,
            defaultValue : this.configDefault.defaultValue
        })
    },
    methods:{
        ...mapActions("FormStore", ["setInitValueFrm", "setValueFrm"])
    }
}