//*----------  COMPONENTS  ----------*/
import DataGrid from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
//*----------  VUEX  ----------*/
import service        from '@/services/soporte'
import authStore              from '@/store/modules/authStore'

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

export default {
    name : "Ticket",
    components : {
        DataGrid
    },
    data : () => ({
        configGrid : {
            summary : true,
            paging : {
                properties : {
                    "page-size" : 25
                }
            },
            export : {
                export          : true,
                exportSelected  : true,
                exportName      : "Servicios",
                texts           : { exportAll : "Exportar toda la información", exportSelectedRows  : "Exportar filas seleccionadas" } 
            },
        },
        actions : [],
        dataColumns: [
            {
                "data-field"    : "id",
                caption         : "ID",
                column          : "idDt",
            }, {
                "data-field"  : "folio",
                caption       : "Folio",
                column        : "folio",
            }, {
                "data-field"  : "titulo",
                caption       : "Titulo",
                column        : "titulo",
            }, {
                "data-field"  : "name",
                caption       : "Responsable",
                column        : "name",
            }, {
                "data-field"  : "descripcion",
                caption       : "Descripción",
                column        : "descripcion",
            }, {
                "data-field"  : "evento",
                caption       : "Evento",
                column        : "evento",
            }, {
                "data-field"  : "servicio",
                caption       : "Servicio",
                column        : "servicio",
            }, {
                "data-field"  : "estatus",
                caption       : "Estatus",
                column        : "estatus",
            }, {
                "data-field"  : "active",
                caption       : "Activo",
                column        : "active",
            }, {
                "data-field" : "fechaC",
                caption      : "Fecha Creación",
                column       : "fechaC",
            }, {
                "data-field" : "userC",
                caption      : "Usuario Creación",
                column       : "userC",
            }, {
                "data-field" : "fechaU",
                caption      : "Fecha Modificación",
                column       : "fechaU",
            }, {
                "data-field" : "userU",
                caption      : "Usuario Modificación",
                column       : "userU",
            }
        ],
        formatExcel     : {
            fileName    : 'Ticket.xlsx',
            worksheet   : 'Ticket',
            salidas     : {
                factor : 1,
                factorG: 1
            },
        }
    }),
    computed : {
       ...mapGetters("Ticket", {
           dataSource : "getDataSource"
        }),
    },
    methods : {
        ...mapActions("Ticket",  ["setDataSource"] ),
        calculateRow(options) {
            switch( options.name ) {
                case  'dia':
                    if (options.summaryProcess === 'start')
                        options.totalValue = { total : 0 };
                    else if ( options.summaryProcess === 'calculate')
                        options.totalValue.total += options.value.total;
                    else if ( options.summaryProcess === 'finalize')
                        options.totalValue = (options.totalValue.total / 3) / 24;
                break;
            }
        },
        eventActionTable({ event, dataAction }) {
            this.$emit("eventActionTable", { event, dataAction })
        },
        setDataGridRef( dataGrid ) {
            this.$emit("setDataGridRef", dataGrid)
        }
    },
    created() {
        service.SopR_dataSourceTickets().then( result => {
            this.setDataSource( this.$JsTable.toObj( result.data ))
        })
        let tipoUsuario = authStore.getters.getUser().idPerfil;
        if ( tipoUsuario == 1 ) {
            this.dataColumns = [
                {
                    "data-field"    : "id",
                    caption         : "ID",
                    column          : "idDt",
                    "sort-order"    : "asc"
                }, {
                    "data-field"  : "folio",
                    caption       : "Folio",
                    column        : "folio",
                }, {
                    "data-field"  : "titulo",
                    caption       : "Titulo",
                    column        : "titulo",
                }, {
                    "data-field"  : "sucursal",
                    caption       : "Sucursal",
                    column        : "sucursal",
                }, {
                    "data-field"  : "name",
                    caption       : "Solicitante",
                    column        : "name",
                }, {
                    "data-field"  : "responsable",
                    caption       : "Responsable",
                    column        : "responsable",
                }, {
                    "data-field"  : "descripcion",
                    caption       : "Descripción",
                    column        : "descripcion",
                }, {
                    "data-field"  : "evento",
                    caption       : "Evento",
                    column        : "evento",
                }, {
                    "data-field"  : "servicio",
                    caption       : "Servicio",
                    column        : "servicio",
                }, {
                    "data-field"  : "estatus",
                    caption       : "Estatus",
                    column        : "estatus",
                }, {
                    "data-field"  : "active",
                    caption       : "Activo",
                    column        : "active",
                }, {
                    "data-field" : "fechaC",
                    caption      : "Fecha Creación",
                    column       : "fechaC",
                }, {
                    "data-field" : "userC",
                    caption      : "Usuario Creación",
                    column       : "userC",
                }, {
                    "data-field" : "fechaU",
                    caption      : "Fecha Modificación",
                    column       : "fechaU",
                }, {
                    "data-field" : "userU",
                    caption      : "Usuario Modificación",
                    column       : "userU",
                }
            ]
        }
        if ( tipoUsuario == 2 ) {
            this.dataColumns = [
                {
                    "data-field"    : "id",
                    caption         : "ID",
                    column          : "idDt",
                    "sort-order"    : "asc"
                }, {
                    "data-field"  : "folio",
                    caption       : "Folio",
                    column        : "folio",
                }, {
                    "data-field"  : "titulo",
                    caption       : "Titulo",
                    column        : "titulo",
                }, {
                    "data-field"  : "sucursal",
                    caption       : "Sucursal",
                    column        : "sucursal",
                }, {
                    "data-field"  : "name",
                    caption       : "Solicitante",
                    column        : "name",
                }, {
                    "data-field"  : "responsable",
                    caption       : "Responsable",
                    column        : "responsable",
                }, {
                    "data-field"  : "descripcion",
                    caption       : "Descripción",
                    column        : "descripcion",
                }, {
                    "data-field"  : "evento",
                    caption       : "Evento",
                    column        : "evento",
                }, {
                    "data-field"  : "servicio",
                    caption       : "Servicio",
                    column        : "servicio",
                }, {
                    "data-field"  : "estatus",
                    caption       : "Estatus",
                    column        : "estatus",
                }, {
                    "data-field"  : "active",
                    caption       : "Activo",
                    column        : "active",
                }, {
                    "data-field" : "fechaC",
                    caption      : "Fecha Creación",
                    column       : "fechaC",
                }, {
                    "data-field" : "userC",
                    caption      : "Usuario Creación",
                    column       : "userC",
                }, {
                    "data-field" : "fechaU",
                    caption      : "Fecha Modificación",
                    column       : "fechaU",
                }, {
                    "data-field" : "userU",
                    caption      : "Usuario Modificación",
                    column       : "userU",
                }
            ]
        }
        if ( tipoUsuario == 3 ) {
            this.dataColumns = [
                {
                    "data-field"    : "id",
                    caption         : "ID",
                    column          : "idDt",
                    "sort-order"    : "asc"
                }, {
                    "data-field"  : "folio",
                    caption       : "Folio",
                    column        : "folio",
                }, {
                    "data-field"  : "titulo",
                    caption       : "Titulo",
                    column        : "titulo",
                }, {
                    "data-field"  : "sucursal",
                    caption       : "Sucursal",
                    column        : "sucursal",
                }, {
                    "data-field"  : "name",
                    caption       : "Solicitante",
                    column        : "name",
                }, {
                    "data-field"  : "responsable",
                    caption       : "Responsable",
                    column        : "responsable",
                }, {
                    "data-field"  : "descripcion",
                    caption       : "Descripción",
                    column        : "descripcion",
                }, {
                    "data-field"  : "estatus",
                    caption       : "Estatus",
                    column        : "estatus",
                }, {
                    "data-field"  : "active",
                    caption       : "Activo",
                    column        : "active",
                }, {
                    "data-field" : "fechaC",
                    caption      : "Fecha Creación",
                    column       : "fechaC",
                }, {
                    "data-field" : "userC",
                    caption      : "Usuario Creación",
                    column       : "userC",
                }, {
                    "data-field" : "fechaU",
                    caption      : "Fecha Modificación",
                    column       : "fechaU",
                }, {
                    "data-field" : "userU",
                    caption      : "Usuario Modificación",
                    column       : "userU",
                }
            ]
        }
    },
    
}
