import AutoComplete     from '@/components/Forms/AutoComplete/AutoComplete.vue'
import RadioGroup       from '@/components/Forms/RadioGroup/RadioGroup.vue'
import TextField        from '@/components/Forms/TextField/TextField.vue'
import DateTimeRange    from '@/components/Forms/DateTime/DateTimeRange/DateTimeRange.vue'
import DatePickerSimple from '@/components/Forms/DateTime/DatePickerSimple/DatePickerSimple.vue'
import CheckBox         from '@/components/Forms/CheckBox/CheckBox.vue'

import {
    mapActions,
    mapGetters
} from 'vuex';

export default {
    name : "FormReport",
    components : {
        AutoComplete,
        RadioGroup,
        TextField,
        DateTimeRange,
        DatePickerSimple,
        CheckBox
    },
    props : {
        fields : {
            type     : Array,
            required : true
        },
        form : {
            type    : Object,
            default : () => {}
        }
    },
    data : () => ({
        configNameFile : {
            label : "Nombre del archivo",
            name  : "nameFile",
            rules : [
                v => !!v || 'Nombre del documento obligatorio',
            ]
        }
    }),  
    computed : {
        ...mapGetters("auth", {
            accessForm : "getComponentAccess"
        }),
        fieldsValidados(){
            this.setAction('report')
            return this.fields.filter((component) => this.accessForm[component.name] !== undefined ? this.accessForm[component.name] : true ) 
        },
    },
    created(){
        this.setFrm(this.form.ref)
    },
    methods:{
        setComponent(c){
            return this.$options._parentVnode.componentOptions.Ctor.options.components[c].name
        },
        clearFrm(){
            this.$refs[this.form.ref].resetValidation();
            this.restoreFrmDefault("form")
        },
        ...mapActions("FormStore", ["setFrm", "restoreFrmDefault"]),
        ...mapActions("auth", ["setAction"])
    }    
}