import {
    mapState,
    mapActions
} from 'vuex'

var currentDate = new Date().toISOString().substr(0,10);

export default {
    name : 'InitFormAdministrador',
    data : () => ({
        overlay     : false,
        panel       : 0,
        loading     : false,
        initForm    : null,
        transition  : 'scale-transition',
        usuario     : undefined,    // FIXME: Se coupan??
        sucursal    : undefined,    // FIXME: Se coupan??
        servicio    : undefined,    // FIXME: Se coupan??
        evento      : undefined,    // FIXME: Se coupan??
        estatus     : undefined,    // FIXME: Se coupan??
        responsable : undefined,    // FIXME: Se coupan??
        items : {
            usuario     : [],
            sucursal    : [],
            servicio    : [],
            evento      : [],
            estatus     : [],
            responsable : []
        },
        pickerFC : {
            menu    : false,
            date    : currentDate
        },
        errores         : { },
        rules           : { }
    }),
    computed : {
        ...mapState("Ticket", [ "dataRender", "dataSelected" ] ),
        itemsSucursal(){
            let itemsSucursal = this.dataRender.sucursal;
            if( this.dataSelected.usuario != undefined ){
                itemsSucursal = itemsSucursal.filter( data => data.idUser == this.dataSelected.usuario );
            }
            return itemsSucursal;
        }
    },
    methods : {
        clearFilter() {
            this.dataSelected.usuario     = undefined;
            this.dataSelected.sucursal    = undefined;
            this.dataSelected.servicio    = undefined;
            this.dataSelected.evento      = { id : 0, name: "", description : "" };
            this.dataSelected.estatus     = undefined;
            this.dataSelected.responsable = undefined;
            this.pickerFC.date            = currentDate;
            this.$emit("applyFilter", [])
        },
        applyFilter() {
            let arrFilter = [];
            if ( this.dataSelected.usuario     != undefined )
                arrFilter.push([ "id_adminCtUser", "=", this.dataSelected.usuario ])
            if ( this.dataSelected.sucursal    != undefined )
                arrFilter.push([ "id_adminCtSucursal", "=", this.dataSelected.sucursal ])
            if ( this.dataSelected.servicio    != undefined )
                arrFilter.push([ "id_soporteCtServicio", "=", this.dataSelected.servicio ])
            if ( this.dataSelected.evento.id   != 0 )
                arrFilter.push([ "id_soporteCtEvento", "=", this.dataSelected.evento.id ])
            if ( this.dataSelected.estatus     != undefined )
                arrFilter.push([ "id_baseCtEstatus", "=", this.dataSelected.estatus ])
            if ( this.dataSelected.responsable != undefined )
                arrFilter.push([ "idUserResponsable", "=", this.dataSelected.responsable ]) 
            arrFilter.push([ "fechaC", "startswith", this.pickerFC.date ])
            this.$emit("applyFilter", arrFilter)
        },
    },
    mounted() {
    }
}